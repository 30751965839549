import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    articles: [],
    topics: [],
    selectedTopic: {},
    itemsPerPage: 9,
    currentPage: 1,
};

export const articlePostsSlice = createSlice({
    name: "article-posts",
    initialState,
    reducers: {
        setArticlesPosts: (state, action) => {
            state.articles = action.payload;
        },
        setTopics: (state, action) => {
            state.topics = action.payload;
        },
        setSelectedTopic: (state, action) => {
            state.selectedTopic = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
    selectors: {
        selectSelectedTopic: state => state.selectedTopic,
        selectTopics: state => state.topics,
        selectFilteredPaginatedArticles: state => {
            const startIndex = (state.currentPage - 1) * state.itemsPerPage;
            const endIndex = startIndex + state.itemsPerPage;

            return state.articles
                ?.filter(a =>
                    a.topic.id.includes(state.selectedTopic?.id || "")
                )
                ?.slice(startIndex, endIndex);
        },
        selectTotalPages: ({ articles, selectedTopic, itemsPerPage }) => {
            const length = articles?.filter(a =>
                a.topic.id.includes(selectedTopic?.id || "")
            )?.length;

            if (!itemsPerPage || !length) return 0;

            return Math.ceil(length / itemsPerPage);
        },
        selectCurrentPage: state => state.currentPage,
    },
});

export const { setArticlesPosts, setSelectedTopic, setTopics, setCurrentPage } =
    articlePostsSlice.actions;

export const {
    selectSelectedTopic,
    selectTopics,
    selectFilteredPaginatedArticles,
    selectFilteredArticlePosts,
    selectTotalPages,
    selectCurrentPage,
} = articlePostsSlice.selectors;
